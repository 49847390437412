// CORE
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as QRCode from 'qrcode';
import { Observable, of, Subscription } from 'rxjs';
// KENDO
import { GridComponent, PageSizeItem, RowClassArgs } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, SortDescriptor, process } from '@progress/kendo-data-query';
import { pdf, exportPDF } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { Align } from '@progress/kendo-angular-popup';
// MODELS
import { KendoDialogPopupData, KendoWindowPopupData } from '../../models/kendo-popup.model';
// SERVICES
import { ApiService } from '../../services/api.service';
import { BroadcastService } from '../../services/broadcast.service';
import { CommonService } from '../../services/common.service';
// CONSTANTS
import { AppConstants, SEARCH_RESULTS } from '../../constants/app.constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-common-grid',
  templateUrl: './common-grid.component.html',
  styleUrls: ['./common-grid.component.scss']
})

export class CommonGridComponent implements OnInit {
  @Input() gridView: any[] = [];
  @Input() GridColumns: any[] = [];
  @Input() pageSize = 10;
  @Input() selectAllType: "ALL" | "CURRENT" = "ALL";
  @Input() totalRecords: number = 0;
  @Input() gridType: string = '';
  @Input() tabelID: string = '';
  @Input() allowSorting: boolean = true;
  @Input() allowColChooser: boolean = true;
  @Input() showColumnFilter: boolean = true;
  @Input() allowResize: boolean = true;
  @Input() allowReorder: boolean = true;
  @Input() allowGrouping: boolean = false;
  @Input() isOptionVisible: boolean = false;
  @Input() isRadioButtonVisible: boolean = false;
  @Input() isOptionCheckBox: boolean = true;
  @Input() isSearchHighlight: boolean = false;
  @Input() standPrgGrp: String;
  @Input() standardCode: String;
  @Input() curPageName: String;
  @Input() companyName: String;
  @Input() companyCode: String;
  resultHeader: String = "";
  @Input() pageable: boolean = true;
  @Output() isProductEnabled = new EventEmitter();
  @Output() colClickListener: EventEmitter<any> = new EventEmitter();
  @Output() dialogPopupObj: EventEmitter<KendoDialogPopupData> = new EventEmitter();
  public errorMsgObj: KendoDialogPopupData = {
    isVisible : false,
    errorMsgTitle : "",
    errorMsgLbl: "",
    errorMsgTxt : ""
  };
  @Output() windowPopupObj: EventEmitter<KendoWindowPopupData> = new EventEmitter();
  public kendoWindowPopupInfo: KendoWindowPopupData = {
    isVisible: false,
    title: "",
    isLoader: false,
    iFrameUrl: ''
  };
  @Input() viewInfoLink:any;
  @Input() viewInfoLinkName:any;
  private subscriptions = new Subscription();
  readonly constants = AppConstants;
  public ENV = environment;
  public isPopupVisible: boolean;
  public serviceURL = this.ENV.serviceURL;
  public gridData: any;
  public items: BreadCrumbItem[] = [];
  public allDatas = [];
  lastPageClick: boolean = false;
  PageSizeChanged: boolean = false;
  pageNoChanged: boolean = false;
  curPgRecstart: number = 0;
  curPgRecEnd: number = 0;
  pagerResp: any = {
    skip: 0,
    take: 10
  };
  pdfClicked: Boolean = false;
  pdfDownloaded: Boolean = false;
  responseMessage: String;
  isExpandAllClicked: Boolean = false;
  searchText: any = sessionStorage.getItem('searchfeild');
  filterData: any = [];
  sortFields: { orderBy: String, isAsc: any } = {
    "orderBy": "",
    "isAsc": ""
  }
  public pageSizes: PageSizeItem[] = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '50', value: 50 },
    { text: '100', value: 100 },
    { text: 'ALL', value: 2000 }

  ];
  pageableSettings = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: false,
    previousNext: true,
    position: "bottom"
  }
  public expandedDetailKeys: number[] = [];
  public sort: SortDescriptor[] = [];
  public path: any = '';
  public filter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  }
  public skip = 0;
  public getRowData:any;  
  
  constructor (
    public commonservice: CommonService,
    private _api: ApiService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private _broadcast: BroadcastService
  ) {
    
      this.subscriptions.add(this._broadcast.getClearFilter().subscribe((data: any) => {
        if(data){
          this.filter = {
            logic: "and",
            filters: [],
          }
          sessionStorage.setItem('facilityFilterData', JSON.stringify(this.filter));
          sessionStorage.setItem('gridFilter', JSON.stringify(this.filter));
        }
      }));

      
  }


  ngOnInit(): void {   
    let pathArray = location.hash.split('/');
    this.path = pathArray[pathArray.length - 1];
    this.path = this.path.split('?')[0];
    this.getFilterData();

    this.allowSorting = true;
    this.showColumnFilter = true;
    this.serviceURL = this.serviceURL.slice(0, -1);
    if ((this.tabelID == 'productDetails' || this.tabelID == 'modelDetails')) {
      this.pageSize = 100;
    }
    this.getGridResponse();

    this.fetchInitialSortOrder().subscribe(sortOrder => {
      this.sortChange(sortOrder);
    });
   this.isPopupVisible = false;
  }
 

  private fetchInitialSortOrder(): Observable<SortDescriptor[]> {
    let getColumnHeader =  this.GridColumns;   
    return of(getColumnHeader?.filter((column: { SORTABLE: string; }) => column.SORTABLE === 'true').map((column: { FIELD: any; }) => ({
      field: column.FIELD,
      dir: 'asc' as 'asc' 
    } as SortDescriptor)));
  }

 
    ngAfterViewInit(): void{
      if ((this.tabelID == 'productDetails' || this.tabelID == 'modelDetails' || this.tabelID == 'categoryDetails')) {
        this.toggleAllFootnotes()
      }     
    }

    
    

  getGridResponse() {
    const header = this._api.getPlainHeader();
    const getSearchResponseUrl = this._api.urls.getSearchResultResponse;
    this.gridData = this.gridView;
  }

  public calculateWidth(width: string) {
    if (width != '0') {
      if (Number(width) < 100) {
        return Number(width) + 30;
      } else {
        return Number(width);
      }
    } else {
      return 130;
    }
  }

  expandDetailsBy = (dataItem: any): number => {
    if (dataItem.MODEL_SEQ != null) {
      return dataItem.MODEL_SEQ;
    } else {
      return dataItem.PRO_SEQ;
    }

  };

  viewModelEvent(dataItem: any, isExpandClicked?: boolean): void {
    let seq = dataItem.MODEL_SEQ;
    if (dataItem.MODEL_SEQ == null) {
      seq = dataItem.PRO_SEQ;
    }
    if (isExpandClicked == true) {
      dataItem.isclicked = this.isExpandAllClicked;
      if (this.isExpandAllClicked == true) {
        let findKeyState = this.checkIfIdExist(seq);
        if (findKeyState === -1) {
          this.expandedDetailKeys.push(seq);
        }
      } else {
        this.expandedDetailKeys = [];
      }
    } else {
      dataItem.isclicked = !dataItem.isclicked;
      let findKeyState = this.checkIfIdExist(seq);
      if (findKeyState === -1) {
        this.expandedDetailKeys.push(seq);
      } else {
        this.expandedDetailKeys.splice(findKeyState, 1);
      }
    }
    this.expandedDetailKeys = [...this.expandedDetailKeys];
  }

  checkIfIdExist(id: number): number {
    return this.expandedDetailKeys.findIndex((i: any) => i === id);
  }

  addViewModelData(proSeq: any, isclicked: boolean, addColumns: any) {
    let index = this.gridData.findIndex(
      (item: any) => item.PRO_SEQ == proSeq
    );
    // console.log('index   ' + index + '    isclicked  ' + isclicked);
    this.getViewModelData(proSeq).then((res: any) => {
      this._broadcast.showLoader(false);
      if (res.status == this.constants.SUCCESS) {
        addColumns = res.viewModelData;
      }
      let i;
      if (isclicked) {
        for (i = addColumns.length - 1; i >= 0; i--) {
          //this.gridData.push(this.addColumns[i]);
          this.gridData.splice(index + 1, 0, addColumns[i]);
        }
      } else {
        //this.gridData.push(this.addColumns[i]);
        this.gridData.splice(
          index,
          addColumns.length + 1,
          this.gridData[index]
        );
      }
      this.gridData = this.gridData.slice(0);
    }, (err) => {
      this._broadcast.showLoader(false);
      // console.log(err);
    }).catch((err) => {
      this._broadcast.showLoader(false);
      // console.log(err);
    })

  };

  toggleFootnoteData(dataItem: any) {
    let footNoteArray = dataItem.RELATED_FOOTNOTES.split("\n");
    dataItem.footNoteArray = footNoteArray;
  }


  getViewModelData(proSeq: String) {
    const header = this._api.getPlainHeader();
    let viewModelData = "";
    const request = {
      "proSeq": proSeq
    }
    let url = this._api.urls.getViewModelData;
    this._broadcast.showLoader(true);

    return new Promise((resolve, reject) => {
      this._api.post(url, request, header).subscribe((response: any) => {
        if (response.success == true && response.data != null) {
          viewModelData = response.data;
          this._broadcast.showLoader(false);
          resolve({
            status: this.constants.SUCCESS,
            viewModelData: viewModelData
          })
        }
      }, (err) => {
        this.responseMessage = err.message || this.constants.EXCEEDED_MAXIMUM_RESULT;
        this._broadcast.setNoDataImage({ iSDefault: false, message: err.message });
        this._broadcast.showLoader(false);
        reject({ status: this.constants.ERROR });
        // console.log("error = ", err);
      });
    })
  }

 
  public async exportToPDF(grid: GridComponent, tabelID: string): Promise<void> {

    let type = 'searchResult';

    if(tabelID == 'productDetails' || tabelID == 'modelDetails' ||  tabelID == 'categoryDetails') {
      type = tabelID;
    }


    let pageLink = await this.commonservice.generateSharedLink(type);
    if(!pageLink) {
      pageLink = '';
    }

    const showResultBy = sessionStorage.getItem('searchType');
    let pageLinkQRBase64 = '';

    try {
      pageLinkQRBase64 = await QRCode.toDataURL(pageLink)
    } catch (err) {
      // console.log("error = ",err);
    }


    let fileName = this.curPageName;
    this.pdfClicked = true;
    let searchParameters: any = [];
    let exclusiveSearchParameters: any = [];
    let fieldFilterParameters: any = [];
    let fieldFilterParam: { parameterId: string, value: string[], parameterType: string } = {
      "parameterId": "",
      "value": [],
      "parameterType": ""
    }
    this.filterData?.filters?.forEach((filter: any) => {
      let fieldFilterParam: { parameterId: string, value: string[], parameterType: string } = {
        "parameterId": filter.field,
        "value": [filter.value],
        "parameterType": ""
      }
      fieldFilterParameters.push(fieldFilterParam);
    })
    if (tabelID == 'modelDetails') {
      let modelData = JSON.parse(sessionStorage.getItem("emitValue") || '{}');
      let dynamicFieldsJSON = JSON.parse(sessionStorage.getItem("sectionData") || 'null');
      if (Array.isArray(dynamicFieldsJSON)) {
        dynamicFieldsJSON.forEach((dynamicField: any) => {
          let searchParam: { parameterId: string, value: string[], parameterType: string,additionalFields:string,isExclusiveFilter: any,isEditable: any,displayName:string } = {
            "parameterId": "",
            "value": [],
            "parameterType": "",
            "additionalFields":"",
            "isExclusiveFilter":"",
            "isEditable":"",
            "displayName":""
            
          }
          let exclusiveSearchParameter: { parameterId: string, value: string[], parameterType: string,additionalFields:string,isExclusiveFilter: any,isEditable: any,displayName:string } = {
            "parameterId": "",
            "value": [],
            "parameterType": "",
            "additionalFields":"",
            "isExclusiveFilter":"",
            "isEditable":"",
            "displayName":""
          }
          if (dynamicField.field_value != undefined && dynamicField.field_value != "") {
            if (dynamicField.field_data_type != 'MULTISELECT') {
                searchParam.parameterId = dynamicField.field_name,
                searchParam.value.push(dynamicField.field_value),
                searchParam.parameterType = dynamicField.field_data_type,
                searchParam.additionalFields = dynamicField.additionalFields,
                searchParam.isExclusiveFilter = dynamicField.isExclusiveFilter,
                searchParam.isEditable = dynamicField.isEditable,
                searchParam.displayName = dynamicField.displayName,

                exclusiveSearchParameter.parameterId = dynamicField.field_name,
                exclusiveSearchParameter.value.push(dynamicField.field_value),
                exclusiveSearchParameter.parameterType = dynamicField.field_data_type,
                exclusiveSearchParameter.additionalFields = dynamicField.additionalFields,
                exclusiveSearchParameter.isExclusiveFilter = dynamicField.isExclusiveFilter,
                exclusiveSearchParameter.isEditable = dynamicField.isEditable
                exclusiveSearchParameter.displayName = dynamicField.displayName


                
            } else if (dynamicField.field_data_type == 'MULTISELECT') {
              searchParam.parameterId = dynamicField.field_name,
                dynamicField.field_value.forEach((fieldValue: any) => {
                  searchParam.value.push(fieldValue.key)
                })
              searchParam.parameterType = dynamicField.field_data_type
              searchParam.additionalFields = dynamicField.additionalFields
              searchParam.isExclusiveFilter = dynamicField.isExclusiveFilter
              searchParam.isEditable = dynamicField.isEditable
              searchParam.displayName = dynamicField.displayName

              exclusiveSearchParameter.parameterId = dynamicField.field_name,
              dynamicField.field_value.forEach((fieldValue: any) => {
                exclusiveSearchParameter.value.push(fieldValue.key)
              })
              exclusiveSearchParameter.parameterType = dynamicField.field_data_type
              exclusiveSearchParameter.additionalFields = dynamicField.additionalFields
              exclusiveSearchParameter.isExclusiveFilter = dynamicField.isExclusiveFilter
              exclusiveSearchParameter.isEditable = dynamicField.isEditable
              exclusiveSearchParameter.displayName = dynamicField.displayName

            }
          }
          if (searchParam.parameterId != "" && searchParam.value.length > 0) {
            searchParameters.push(searchParam);
          }
          if (exclusiveSearchParameter.parameterId != "" && exclusiveSearchParameter.value.length > 0) {
            exclusiveSearchParameters.push(exclusiveSearchParameter);
         }
        })
      }
      const inputData = exclusiveSearchParameters    
      const exclusiveYFilterParameters = inputData.filter((parameter: { isExclusiveFilter: string; }) => parameter.isExclusiveFilter === "Y");
      const inputSearchData = searchParameters    
      const exclusiveNFilterParameters = inputSearchData.filter((parameter: { isExclusiveFilter: string; }) => parameter.isExclusiveFilter === "N");
      let request: any = {
        "modelSeq": modelData.selectedSeq,
        "exportFormat": "PDF",
        "searchParameters": exclusiveNFilterParameters,
        "exclusiveSearchParameters":this.commonservice.isValid(exclusiveYFilterParameters) ? exclusiveYFilterParameters : [],
        "fieldFilterParameters":fieldFilterParameters,
        "orderBy": this.sortFields?.orderBy,
        "isAsc": this.sortFields?.isAsc,
        "listingsDetailsConditionalParams":JSON.parse(sessionStorage.getItem("rowData") || '{}'),
        "modelListingsDetailsConditionalParams"  :JSON.parse(sessionStorage.getItem("productToModel") || '[]'),
        showResultBy,
        pageLinkQRBase64,
        pageLink
      }

      if (modelData.redirectedFrom != 'SearchResult') {
        let productData = JSON.parse(sessionStorage.getItem("viewListingDetails") || '{}');
        request.proSeq = productData?.selectedSeq,
          request.model = modelData?.modelName
      }
      if(modelData.listingType  == 'TRADE_DESIGNATION'){
        request= {
          proSeq : modelData?.pro_seq,
          tradeDesignation :  modelData?.tradeDesignation,
          pageLinkQRBase64,
          showResultBy: showResultBy,
          pageLink,
          "listingsDetailsConditionalParams":JSON.parse(sessionStorage.getItem("rowData") || '{}'),
          "modelListingsDetailsConditionalParams"  :JSON.parse(sessionStorage.getItem("productToModel") || '[]'),
        }
      }
      if(modelData.listingType  == 'TRADE_NAME'){
        request= {
          proSeq : modelData?.pro_seq,
          tradeName :  modelData?.tradeName,
          pageLinkQRBase64,        
          showResultBy: showResultBy,
          pageLink,
          "listingsDetailsConditionalParams":JSON.parse(sessionStorage.getItem("rowData") || '{}'),
          "modelListingsDetailsConditionalParams"  :JSON.parse(sessionStorage.getItem("productToModel") || '[]'),
        }
      }
      if(modelData.listingType  == 'SUCTION_OUTLET_FITTING_MODEL'){
        request= {
          proSeq : modelData?.pro_seq,
          modelSeq :  modelData?.selectedSeq,
          "exportFormat": "PDF",
          "searchParameters": exclusiveNFilterParameters,
          "exclusiveSearchParameters":this.commonservice.isValid(exclusiveYFilterParameters) ? exclusiveYFilterParameters :  [],
          "fieldFilterParameters":fieldFilterParameters,
          suctionOutletFittingModel: modelData?.suctionOutletFittingModel,
          pageLinkQRBase64,
          showResultBy: showResultBy,
          pageLink,
          "listingsDetailsConditionalParams":JSON.parse(sessionStorage.getItem("rowData") || '{}'),
          "modelListingsDetailsConditionalParams"  :JSON.parse(sessionStorage.getItem("productToModel") || '[]')
        }      
      }
      if(modelData.listingType  == 'SUCTION_FITTING_MODEL'){
        request= {
          proSeq : modelData?.pro_seq,
          modelSeq :  modelData?.selectedSeq,
          "exportFormat": "PDF",
          "searchParameters": exclusiveNFilterParameters,
          "exclusiveSearchParameters":this.commonservice.isValid(exclusiveYFilterParameters) ? exclusiveYFilterParameters :  [],
          "fieldFilterParameters":fieldFilterParameters,
          suctionFittingModel: modelData?.suctionFittingModel,
          pageLinkQRBase64,
          showResultBy: showResultBy,
          pageLink,
          "listingsDetailsConditionalParams":JSON.parse(sessionStorage.getItem("rowData") || '{}'),
          "modelListingsDetailsConditionalParams"  :JSON.parse(sessionStorage.getItem("productToModel") || '[]')
        }       
      }
      if(modelData.listingType  == 'MODEL_NUMBER'){
        request= {
          proSeq : modelData?.pro_seq,
          modelSeq :  modelData?.selectedSeq,
          "exportFormat": "PDF",
          "searchParameters": exclusiveNFilterParameters,
          "exclusiveSearchParameters":this.commonservice.isValid(exclusiveYFilterParameters) ? exclusiveYFilterParameters :  [],
          "fieldFilterParameters":fieldFilterParameters,
          modelNumber: modelData?.modelNumber,
          pageLinkQRBase64,
          showResultBy: showResultBy,
          pageLink,
          "listingsDetailsConditionalParams":JSON.parse(sessionStorage.getItem("rowData") || '{}'),
          "modelListingsDetailsConditionalParams"  :JSON.parse(sessionStorage.getItem("productToModel") || '[]')
        }        
      }
      // fileName = modelData.SectionName;
      let getSearcReq = JSON.parse(sessionStorage.getItem("searchRequest") || '{}');

      let asignRequest = {...request,...getSearcReq }
      asignRequest.exportStandPrgGrp = this.standPrgGrp;
      asignRequest.exportStandardCode = this.standardCode;
      let url = this._api.urls.exportModelGridData;
      this.commonservice.processExportData(asignRequest, "pdf", url, fileName);
    } else if (tabelID == 'categoryDetails') {
      let categoryData = JSON.parse(sessionStorage.getItem("emitValue") || '{}');
      if (categoryData.viewListingDetails.indexOf("VIEW_CATEGORY_MODEL_DETAILS") < 0) {
        categoryData = JSON.parse(sessionStorage.getItem("viewListingDetails") || '{}');
      }
      const inputData = exclusiveSearchParameters    
      const exclusiveYFilterParameters = inputData.filter((parameter: { isExclusiveFilter: string; }) => parameter.isExclusiveFilter === "Y");
      const inputSearchData = searchParameters    
      const exclusiveNFilterParameters = inputSearchData.filter((parameter: { isExclusiveFilter: string; }) => parameter.isExclusiveFilter === "N");
      const request = {
        "catSeq": categoryData.selectedSeq,
        "exportFormat": "PDF",
        "searchParameters": exclusiveNFilterParameters,
        "exclusiveSearchParameters":this.commonservice.isValid(exclusiveYFilterParameters) ? exclusiveYFilterParameters :  [],
        "fieldFilterParameters": fieldFilterParameters,
        "orderBy": this.sortFields?.orderBy,
        "isAsc": this.sortFields?.isAsc,
        "listingsDetailsConditionalParams":JSON.parse(sessionStorage.getItem("rowData") || '{}'),
        "modelListingsDetailsConditionalParams"  :JSON.parse(sessionStorage.getItem("productToModel") || '[]'),
        showResultBy,
        pageLinkQRBase64,
        pageLink
      }
      // fileName = productData.SectionName;
      let getSearcReq = JSON.parse(sessionStorage.getItem("searchRequest") || '{}');
      let asignRequest = {...request,...getSearcReq }
      asignRequest.exportStandPrgGrp = this.standPrgGrp;
      asignRequest.exportStandardCode = this.standardCode;
      let url = this._api.urls.exportCategoryGridData;
      this.commonservice.processExportData(asignRequest, "pdf", url, fileName);
    }else if (tabelID == 'productDetails') {
      let productData = JSON.parse(sessionStorage.getItem("emitValue") || '{}');
      if (productData.viewListingDetails.indexOf("VIEW_PRODUCT_LISTING_DETAILS") < 0) {
        productData = JSON.parse(sessionStorage.getItem("viewListingDetails") || '{}');
      }
      const inputData = exclusiveSearchParameters    
      const exclusiveYFilterParameters = inputData.filter((parameter: { isExclusiveFilter: string; }) => parameter.isExclusiveFilter === "Y");
      const inputSearchData = searchParameters    
      const exclusiveNFilterParameters = inputSearchData.filter((parameter: { isExclusiveFilter: string; }) => parameter.isExclusiveFilter === "N");
      const request = {
        "proSeq": productData.selectedSeq,
        "exportFormat": "PDF",
        "searchParameters": exclusiveNFilterParameters,
        "exclusiveSearchParameters":this.commonservice.isValid(exclusiveYFilterParameters) ? exclusiveYFilterParameters :  [],
        "fieldFilterParameters":fieldFilterParameters,
        "orderBy": this.sortFields?.orderBy,
        "isAsc": this.sortFields?.isAsc,
        "listingsDetailsConditionalParams":JSON.parse(sessionStorage.getItem("rowData") || '{}'),
        "modelListingsDetailsConditionalParams"  :JSON.parse(sessionStorage.getItem("productToModel") || '[]'),
        showResultBy,
        pageLinkQRBase64,
        pageLink
      }
      // fileName = productData.SectionName;
      let getSearcReq = JSON.parse(sessionStorage.getItem("searchRequest") || '{}');
      let asignRequest = {...request,...getSearcReq };
      asignRequest.exportStandPrgGrp = this.standPrgGrp;
      asignRequest.exportStandardCode = this.standardCode;
      let url = this._api.urls.exportProductGridData;
      this.commonservice.processExportData(asignRequest, "pdf", url, fileName);
    } else {
      let regionData = [] as any;
      let countryData = [] as any;
      if (sessionStorage.getItem("advSearchType") === 'region') {
        regionData = await this.commonservice.getCountryNRegionValues('region');
        countryData = null;
      } else {
        regionData = null;
        countryData = await this.commonservice.getCountryNRegionValues('country');
      }
      let advFilter = JSON.parse(sessionStorage.getItem("advFilter") || 'null');
      let dynamicFieldsJSON = JSON.parse(sessionStorage.getItem("sectionData") || 'null');

      if (Array.isArray(dynamicFieldsJSON)) {
        dynamicFieldsJSON.forEach((dynamicField: any) => {
          let searchParam: { parameterId: string, value: string[], parameterType: string,additionalFields:string,isExclusiveFilter: any,isEditable: any,displayName:string } = {
            "parameterId": "",
            "value": [],
            "parameterType": "",
            "additionalFields":"",
            "isExclusiveFilter":"",
            "isEditable":"",
            "displayName":""
            
          }
          let exclusiveSearchParameter: { parameterId: string, value: string[], parameterType: string,additionalFields:string,isExclusiveFilter: any,isEditable: any,displayName:string } = {
            "parameterId": "",
            "value": [],
            "parameterType": "",
            "additionalFields":"",
            "isExclusiveFilter":"",
            "isEditable":"",
            "displayName":""  
          }
          if (dynamicField.field_value != undefined && dynamicField.field_value != "") {
            if (dynamicField.field_data_type != 'MULTISELECT') {
                searchParam.parameterId = dynamicField.field_name,
                searchParam.value.push(dynamicField.field_value),
                searchParam.parameterType = dynamicField.field_data_type,
                searchParam.additionalFields = dynamicField.additionalFields
                searchParam.isExclusiveFilter = dynamicField.isExclusiveFilter
                searchParam.isEditable = dynamicField.isEditable
                searchParam.displayName = dynamicField.displayName

                exclusiveSearchParameter.parameterId = dynamicField.field_name,
                exclusiveSearchParameter.value.push(dynamicField.field_value),
                exclusiveSearchParameter.parameterType = dynamicField.field_data_type,
                exclusiveSearchParameter.additionalFields = dynamicField.additionalFields
                exclusiveSearchParameter.isEditable = dynamicField.isEditable
                exclusiveSearchParameter.displayName = dynamicField.displayName
            } else if (dynamicField.field_data_type == 'MULTISELECT') {
              searchParam.parameterId = dynamicField.field_name,
                dynamicField.field_value.forEach((fieldValue: any) => {
                  searchParam.value.push(fieldValue.key)
                })
              searchParam.parameterType = dynamicField.field_data_type
              searchParam.additionalFields = dynamicField.additionalFields
              searchParam.isExclusiveFilter = dynamicField.isExclusiveFilter
              searchParam.isEditable = dynamicField.isEditable
              searchParam.displayName = dynamicField.displayName

              exclusiveSearchParameter.parameterId = dynamicField.field_name,
                dynamicField.field_value.forEach((fieldValue: any) => {
                  exclusiveSearchParameter.value.push(fieldValue.key)
                })
                exclusiveSearchParameter.parameterType = dynamicField.field_data_type
                exclusiveSearchParameter.additionalFields = dynamicField.additionalFields
                exclusiveSearchParameter.isExclusiveFilter = dynamicField.isExclusiveFilter
                exclusiveSearchParameter.isEditable = dynamicField.isEditable
                exclusiveSearchParameter.displayName = dynamicField.displayName
            }
          }
          if (searchParam.parameterId != "" && searchParam.value.length > 0) {
            searchParameters.push(searchParam);
          }
          if (exclusiveSearchParameter.parameterId != "" && exclusiveSearchParameter.value.length > 0) {
            exclusiveSearchParameters.push(searchParam);
          }
        })
      }
      const inputData = exclusiveSearchParameters    
      const exclusiveYFilterParameters = inputData.filter((parameter: { isExclusiveFilter: string; }) => parameter.isExclusiveFilter === "Y");
      const inputSearchData = searchParameters    
      const exclusiveNFilterParameters = inputSearchData.filter((parameter: { isExclusiveFilter: string; }) => parameter.isExclusiveFilter === "N");      
      const request = {
        "region": regionData,
        "country": countryData,
        "searchText": sessionStorage.getItem("searchfeild"),
        "category": advFilter?.category || null,
        "subCategory": advFilter?.subCategory || null,
        "standPrgGrp": advFilter?.standard || null,
       // "exportStandPrgGrp": this.standPrgGrp,
        "standardCode": advFilter?.standardCode || null,
        "exportFormat": "PDF",
        "company": advFilter?.company || null,
        "companyCode": this.companyCode ? this.companyCode : '',
        "location": advFilter?.location || null,
        "searchParameters": exclusiveNFilterParameters,
        "exclusiveSearchParameters":this.commonservice.isValid(exclusiveYFilterParameters) ? exclusiveYFilterParameters :  [],
        "fieldFilterParameters":fieldFilterParameters,
        "orderBy": this.sortFields?.orderBy,
        "isAsc": this.sortFields?.isAsc,
        "exportStandPrgGrp":this.standPrgGrp,
        "exportStandardCode": this.standardCode,
        showResultBy,
        "pageLinkQRBase64": null,
        pageLink,
      }
   
      let url:string = '';
      if (this.companyName !== undefined && this.companyName !== null && this.companyName !== "") {
        url = this._api.urls.exportComapnyGridData;
      } else {
        url = this._api.urls.exportGridData;
      }
      this.commonservice.processExportData(request, "pdf", url, fileName);
    }


    setTimeout(() => {
      this.pdfClicked = false;
      this.pdfDownloaded = true;
      setTimeout(() => {
        this.pdfClicked = false;
        this.pdfDownloaded = false;
      }, 1500);
    }, 500);
  }

  showOnlyModelDetails(dataItem: any) {
    let footNoteArray = dataItem.RELATED_FOOTNOTES?.split("\n");
    dataItem.footNoteArray = footNoteArray;
    let isFootNoteAvailable = false;
    let dataType = typeof dataItem.RELATED_FOOTNOTES;
    if (footNoteArray?.length > 0 && JSON.stringify(footNoteArray).length > 10) {
      isFootNoteAvailable = true;
    }
    return isFootNoteAvailable;
  }


  getTitle(colTitle: string) {
    let title: string;
    title = (this.constants as any)[colTitle];
    return title;
  }

  sendDownloadData(fileName:any){
     let getBaseUrl  = JSON.parse(sessionStorage.getItem('envInfo') || '{}');
     this.downloadSeLink(getBaseUrl?.PUBLIC_LISTINGS_SELNK + fileName, fileName)
  }

  certDownloadData(fileName:any){
    let getBaseUrl  = JSON.parse(sessionStorage.getItem('envInfo') || '{}');
    this.downloadSeLink(getBaseUrl?.PUBLIC_LISTINGS_CERTIFICATES + fileName, fileName)
  }

  registrationDownloadData(fileName:any){
    let getBaseUrl  = JSON.parse(sessionStorage.getItem('envInfo') || '{}');
    this.downloadSeLink(getBaseUrl?.PUBLIC_LISTINGS_SUSTAIN + fileName, fileName)
  }

  productDesignationDownloadData(fileName:any){
    let getBaseUrl  = JSON.parse(sessionStorage.getItem('envInfo') || '{}');
    this.downloadSeLink(getBaseUrl?.PUBLIC_LISTINGS_NF_LETTERS + fileName, fileName)
  }

  downloadSeLink(url:any, filename:any) {
    fetch(url)
    .then(async(response) => {
        // status 404 or 500 will set ok to false
        if (response.ok) {
            // Success: convert data received & run callback
            const result = await response.blob().then(blob =>{
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = filename;
              link.click();
            });
            callback(result);
        }
        else {
          this.errorMsgObj.isVisible = true;
          this.errorMsgObj.errorMsgTitle = "404: File Not Found";
          this.errorMsgObj.errorMsgLbl = "We are sorry!";
          this.errorMsgObj.errorMsgTxt = `The file you have selected is not currently available to download.
          We are actively working to resolve this issue, please kindly check back later.`;
          this.dialogPopupObj.emit(this.errorMsgObj);
        }
    }).catch(e => "")
  }

  openInfoLink(){    
      let url =  this.viewInfoLink
      this.kendoWindowPopupInfo.title =  this.viewInfoLinkName;
      this.setCommonData(url);
  }

  private setCommonData(url: any) {
    this.kendoWindowPopupInfo.isVisible = true;
    this.kendoWindowPopupInfo.isLoader = true;
    this.kendoWindowPopupInfo.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.windowPopupObj.emit(this.kendoWindowPopupInfo);
  }


  sendClickedData(selectedSeq: any, viewListingDetails: string, listingType: String, itemClicked?: string , fulldata?:any) {
    this._broadcast.setClearFilter(false);
    if ((listingType == "END_USES") || (listingType == "FOR_USE_WITH")) {
      this.kendoWindowPopupInfo.title =  this.constants.WINDOW_TILE_END_USE;
      let getEndUse  = JSON.parse(sessionStorage.getItem('envInfo') || '{}');
      let url;
      if(fulldata?.END_USES_LINK){
        url = fulldata?.END_USES_LINK
      }else{
        url = getEndUse?.PUBLIC_LISTINGS_ENDUSE;
      }
      this.setCommonData(url);
    } else if(listingType == "NONFOOD_CATEGORY") {  
      this.kendoWindowPopupInfo.title =  this.constants.WINDOW_TILE_NONFOOD_CATEGORY;    
      let getPressureURL  = JSON.parse(sessionStorage.getItem('envInfo') || '{}');
      let url =  getPressureURL?.PUBLIC_LISTINGS_NF_CATEGORIES;
      this.setCommonData(url);
    } else if(listingType == "MAX_PRESSURE") {
      this.kendoWindowPopupInfo.title =  this.constants.WINDOW_TILE_HIGH_PRESSURE;
      let getPressureURL  = JSON.parse(sessionStorage.getItem('envInfo') || '{}');
      let url =  getPressureURL?.PUBLIC_LISTINGS_HIGHLOW_PRESSURE;
      this.setCommonData(url);
    } else if (listingType == "WATER_CONTACT_TEMP") {
      this.kendoWindowPopupInfo.title =  this.constants.WINDOW_TILE_WATER_CONT_TEMP;
      let getPressureURL  = JSON.parse(sessionStorage.getItem('envInfo') || '{}');
      let url =  getPressureURL?.PUBLIC_LISTINGS_WCT;
      this.setCommonData(url);
    }else if(listingType == "WATER_CONTACT_MATERIAL") {
      this.kendoWindowPopupInfo.title =  this.constants.WINDOW_TILE_WATER_CONT_MATERIAL;
      let getPressureURL  = JSON.parse(sessionStorage.getItem('envInfo') || '{}');
      let url =  getPressureURL?.PUBLIC_LISTINGS_WCM;
      this.setCommonData(url);
    } else if(listingType == "MAX_TEMP") {
      this.kendoWindowPopupInfo.title =  this.constants.WINDOW_TILE_LOW_PRESSURE;
      let getPressureURL  = JSON.parse(sessionStorage.getItem('envInfo') || '{}');
      let url =  getPressureURL?.PUBLIC_LISTINGS_HIGHLOW_PRESSURE;
      this.setCommonData(url);
    } else {
      let modelName: any = "";
      let urlType = "product-details";
      if(fulldata?.VIEW_CATEGORY_LISTING_DETAILS){
        urlType = "category-details";
        listingType = "VIEW_CATEGORY_LISTING_DETAILS"
        viewListingDetails = fulldata?.VIEW_CATEGORY_LISTING_DETAILS
      }

      this.items = [{
        text: SEARCH_RESULTS,
        title: SEARCH_RESULTS
      }];
      if (listingType == "VIEW_MODEL_LISTING_DETAILS") {
        urlType = "model-details";
        this.items = [{
          text: SEARCH_RESULTS,
          title: SEARCH_RESULTS
        }];
      } else if (listingType == "MODEL" || listingType == 'MODEL_NUMBER' || listingType == 'SUCTION_OUTLET_FITTING_MODEL'  || listingType == 'SUCTION_FITTING_MODEL') {
        modelName = itemClicked;
        urlType = "model-details";
        this.items = [{
          text: SEARCH_RESULTS,
          title: SEARCH_RESULTS
        },
        {
          text: 'Product Listing Details',
          title: 'Product Listing Details'
        }];
      } else if (listingType == "TRADE_NAME" || listingType == "TRADE_DESIGNATION") {
        modelName = itemClicked;
        let getBcValues = JSON.parse(sessionStorage.getItem("BCitems") || '{}');       
        urlType = "model-details";
        this.items = [{
          text: SEARCH_RESULTS,
          title: SEARCH_RESULTS
        },
        {
          text: getBcValues.text,
          title: getBcValues.title
        }];
      } else if (listingType == "VIEW_CATEGORY_LISTING_DETAILS") {
        urlType = "category-details";
      }

     

      let finaldata: { parameterId: string; value: string[]; }[] = [];
      let navigateFrom = fulldata?.LST_TYPE
      if(navigateFrom === "SEARCH"){
        for (let prop in fulldata) {
          if (prop != "footNoteArray" && prop!="CERTIFICATE_NUMBER_LINK"  && prop!="REGISTRATION_NUMBER_LINK" && prop!="PRODUCT_DESIGNATION_LINK" && prop != "PERFORMANCE_STANDARD_LINK" && prop !="MAX_PRESSURE_LINK" && prop !="MAX_TEMP_LINK"){
            let assignParam: { parameterId: string, value: string[]} = {
              "parameterId":prop,
              "value": [fulldata[prop]]
            }
            finaldata.push(assignParam);
          }
        }
      }else{
        finaldata = JSON.parse(sessionStorage.getItem("rowData") || '{}'); 
      }
           

      let emitValue = {
        selectedSeq: selectedSeq,
        viewListingDetails: viewListingDetails,
        urlType: urlType,
        modelName: modelName,
        redirectedFrom: this.curPageName,
        items: this.items,
        pro_seq:fulldata?.PRO_SEQ,
        listingType:listingType,
        rowData: finaldata,
        suctionOutletFittingModel:"",
        suctionFittingModel:"",
        modelNumber:"",
        tradeName:"",
        tradeDesignation:""
      }
      if(listingType == 'SUCTION_OUTLET_FITTING_MODEL'){
        emitValue.suctionOutletFittingModel = fulldata?.SUCTION_OUTLET_FITTING_MODEL
      }
      if(listingType == 'SUCTION_FITTING_MODEL'){
        emitValue.suctionFittingModel = fulldata?.SUCTION_FITTING_MODEL
      }
      if(listingType == 'MODEL_NUMBER'){
        emitValue.modelNumber = fulldata?.MODEL_NUMBER
      }
      if(listingType == 'TRADE_NAME'){
        emitValue.tradeName = fulldata?.TRADE_NAME
      }
      if(listingType == 'TRADE_DESIGNATION'){
        emitValue.tradeDesignation = fulldata?.TRADE_DESIGNATION
      }
      this.getRowData = finaldata;
      sessionStorage.setItem("rowData", JSON.stringify(finaldata));
      if(fulldata){
        let getConvertData :{ parameterId: string; value: string[]; }[] = [];
        for (let prop in fulldata) {
          if (prop != "footNoteArray" && prop != "isclicked" && prop!="CERTIFICATE_NUMBER_LINK"  && prop!="REGISTRATION_NUMBER_LINK" && prop!="PRODUCT_DESIGNATION_LINK" && prop != "PERFORMANCE_STANDARD_LINK" && prop !="MAX_PRESSURE_LINK" && prop !="MAX_TEMP_LINK"){
            let productToMod: { parameterId: string, value: string[]} = {
              "parameterId":prop,
              "value": [fulldata[prop]]
            }
            getConvertData.push(productToMod);
          }
        }
        sessionStorage.setItem("productToModel", JSON.stringify(getConvertData));   
      }
        
      if ((urlType == 'product-details' ) || (urlType == "category-details")) {
        sessionStorage.setItem("viewListingDetails", JSON.stringify(emitValue));
      }
      window.scrollTo(50, 50);
      this.colClickListener.emit(emitValue);
    }
  }

  modelClickedData(selectedSeq: any) {
    window.scrollTo(50, 50);
    this.colClickListener.emit(selectedSeq);
  }

  onPageSizeChange(args: any) {
    this.PageSizeChanged = true;
  }

  changePageNo(arg: any) {
    // console.log(arg);
    if (this.gridData.data.length < this.totalRecords && this.pagerResp?.take + this.pagerResp?.skip >= this.gridData.total) {
      this.pageNoChanged = true;
    }

  }

  updateGridPager() {
    if (this.gridData && this.gridData.length > 0) {
      this.curPgRecstart = 1;
      this.curPgRecEnd = (this.gridData.length >= this.pageSize) ? this.pageSize : this.gridData.length;
      // this.curPgRecstart = this.curPgRecstart ? this.curPgRecstart : 1;
      // this.curPgRecEnd = (this.gridData.data.length >= this.pageSize) ? this.curPgRecEnd == 0 ? this.pageSize : this.curPgRecEnd : this.gridData.data.length;
    } else {
      this.curPgRecstart = 0;
      this.curPgRecEnd = 0;
    }
    this.allDatas = JSON.parse(JSON.stringify(this.gridData));
    let optionalFields = {};
    this.gridData = process(this.allDatas, optionalFields);
    // this.responsiveData = this.gridData.data.slice(this.responsiveSkipPg, this.responsiveSkipPg + this.pageSize);
  }

  public rowCallback(context: RowClassArgs) {

    if (context.dataItem.IS_MODEL === "FALSE") {
      return {
        parentRow: true
      };
    } else if (context.dataItem.IS_MODEL === "TRUE") {
      return {
        childRow: true
      };
    }

    if (context.dataItem.isclicked == true) {
      return {
        modelClicked: true
      };
    }

    if (context.dataItem.NAME == "") {
      return {
        childRows: true
      };
    } else {
      return {
        childRows: false
      };
    }

  }

  /**
   * @ngdoc method
   * @name allData
   * @methodOf gridComponent
   * @description
   * Export all datas
   */
  public allData(): ExcelExportData {
    let visitData = JSON.parse(JSON.stringify(this.gridData));
    const result: ExcelExportData = {
      data: process(visitData, {}).data
    };

    return result;
  }

  isViewModel(item: any) {
    return (item.indexOf('VIEW_MODEL') > -1);
  }

  calcViewModelCount(dataItem: any, item: any) {
    let count = item.split("(")[1].split(")")[0];
    dataItem.viewModelCount = count;
  }

  toggleAllFootnotes() {
    this.isExpandAllClicked = !this.isExpandAllClicked;   
    this.gridData.forEach((row: any) => {
      if (Array.isArray(row.footNoteArray) && JSON.stringify(row.footNoteArray).length > 10) {
        this.viewModelEvent(row, true);
      }
    })
  }

  public filterChange($event: any) {
    this.filterData = $event;
    this.setFilterData('filter');
    sessionStorage.setItem('facilityFilterData', JSON.stringify(this.filterData));
  }

  public sortChange(sort: SortDescriptor[]) {
    let $event: any = sort;
  
    if ($event.sort.length > 0) {
      if ($event.length > 0) {
        if ($event[0].dir === undefined) {
          this.sortFields.isAsc = null;
          this.sortFields.orderBy = "";
        } else {
          this.sortFields.isAsc = $event[0].dir ? $event[0].dir === 'asc' ? true : false : false;
          let orderByHeader = this.GridColumns.find(
            (el: any) => el.FIELD === $event[0].field
          );
          this.sortFields.orderBy = orderByHeader.FIELD;  
        }
      }
    }
  
    this.sort = sort;
    this.setFilterData('sort');
  }

  setFilterData(type: any, skip?: any) {
    let sortObject: any = JSON.parse(sessionStorage.getItem('gridFilter') || '{}');
    if (!sortObject[this.path]) {
      sortObject[this.path] = {};
    }
    if (type === 'sort') {
      if (sortObject[this.path][this.tabelID]) {
        sortObject[this.path][this.tabelID]['sort'] = this.sort;
      } else {
        sortObject[this.path][this.tabelID] = {
          sort: this.sort
        }
      }
    } else if (type === 'filter') {
      if (sortObject[this.path][this.tabelID]) {
        sortObject[this.path][this.tabelID]['filter'] = this.filterData;
        sortObject[this.path][this.tabelID]['page'] = 0;
        this.skip = 0;
      } else {
        sortObject[this.path][this.tabelID] = {
          filter: this.filterData
        }
      }
    } else if (type === 'page') {
      if (sortObject[this.path][this.tabelID]) {
        sortObject[this.path][this.tabelID]['page'] = skip;
      } else {
        sortObject[this.path][this.tabelID] = {
          page: skip
        }
      }
    }
    sessionStorage.setItem('gridFilter', JSON.stringify(sortObject));
  }

  getFilterData() {
    let sortObject: any = JSON.parse(sessionStorage.getItem('gridFilter') || '{}');
    let filterData = { ...sortObject };
    switch (this.path) {
      case 'search-result':
        filterData = {};
        filterData[this.path] = sortObject[this.path];
        sessionStorage.setItem('gridFilter', JSON.stringify(filterData));
        break;
      case 'product-details':

        break;
      default:
    }
    if (filterData[this.path]) {
      this.sort = filterData[this.path][this.tabelID]?.sort || [];
      this.filter = filterData[this.path][this.tabelID]?.filter || { logic: "and", filters: [] };
      this.skip = filterData[this.path][this.tabelID]?.page || 0;
    } else {
      this.sort = [];
      this.filter = {
        logic: "and",
        filters: [],
      }
    }
  }

  pageChange(event: any) {   
    this.setFilterData('page', event.skip);
    if ((this.tabelID == 'productDetails' || this.tabelID == 'modelDetails' || this.tabelID == 'categoryDetails')) {   
    this._broadcast.showLoader(true);   
    this.isExpandAllClicked = !this.isExpandAllClicked;   
    setTimeout(() => {
    this._broadcast.showLoader(false);
    this.toggleAllFootnotes();     
    }, 5000);     
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
function callback(result: void) {
  throw new Error('Function not implemented.');
}

